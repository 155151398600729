<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
             <selectComp
              :hval="orderStatus"
              :data="orderStatusList"
              @change="orderStatusChange"
              placeholder="工单状态"
            />
            <goodCategory
              :hval="categoryId"
              @change="categoryIdChange"
            />
            <selectComp
              :hval="priceType"
              :data="priceTypeList"
              @change="priceTypeChange"
              placeholder="处罚金额"
            />
            <selectComp
              prop="type"
              placeholder="处理方式"
              :data="typeList"
              :hval="type"
              @change="typeChange"
            />
            <dateComp
              :hval="date"
              startPlaceholder="报修开始日期"
              endPlaceholder="报修结束日期"
              @change="dateChange"
            />
            <btnGroup @search="search" @reset="reset" />
             <btnComp @click="exportData"
              >导出列表</btnComp>
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="num" label="处罚数量/开箱不良/报修数量">
            <template v-slot="scope">
              <span>
                {{ scope.row.punishCount || '-' }} /
                {{ scope.row.poorUnpackingCount || '-' }} /
                {{ scope.row.amount || '-' }}
              </span>
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
  </div>
</template>

<script>
import goodCategory from '@/views/components/goodCategory'
import { storeOrderAuditList, qcList, qcListExport } from '@/api'
import { orderStatusListToQC, priceTypeList } from '@/utils/constData'
export default {
  components: {
    goodCategory
  },
  data () {
    return {
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      code: '',
      orderStatus: '',
      categoryId: '',
      priceType: '',
      type: '',
      startTime: '',
      endTime: '',
      date: [],
      typeList: [
        { label: '远程处理', value: 1 },
        { label: '上门处理', value: 2 }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  computed: {
    // 定义常量
    orderStatusList () {
      return orderStatusListToQC
    },
    priceTypeList () {
      return priceTypeList
    },
    // 根据路由判断是否是品控的工单详情
    isQcPunish () {
      return this.$route.name === 'qc-punish-detail'
    },
    // 平台收款和商家品控明细的表格区分
    theadName () {
      return this.isQcPunish
        ? [
          { prop: 'price', label: '处罚金额' },
          { prop: 'statusName', label: '品控状态' },
          { prop: 'orderStatusName', label: '工单状态' },
          { prop: 'code', label: '报修工单号' },
          { prop: 'storeName', label: '商家' },
          { prop: 'categoryName', label: '设备分类' },
          { prop: 'orderCreatedTime', label: '报修时间' },
          { prop: 'typeName', label: '处理方式' },
          { prop: 'satisfactionName', label: '客户满意度' },
          { slot: 'num' }
        ]
        : [
          { prop: 'managerPrice', label: '后台成本' },
          { prop: 'statusName', label: '状态' },
          { prop: 'code', label: '工单编号' },
          { prop: 'storeName', label: '商家' },
          { prop: 'categoryName', label: '设备分类' },
          { prop: 'amount', label: '报修数量(台)' },
          { prop: 'typeName', label: '处理方式' },
          { prop: 'provinceCityRegion', label: '上门区县' },
          { prop: 'visitPrice', label: '上门成本' }
        ]
    }
  },
  methods: {
    getList () {
      this.params = {
        orderPaymentStoreId: this.$route.query.id,
        curPage: this.curPage,
        pageSize: this.pageSize,
        code: this.code,
        orderStatus: this.orderStatus,
        categoryId: this.categoryId,
        priceType: this.priceType,
        type: this.type,
        startTime: this.startTime,
        endTime: this.endTime

      }
      // 平台收款和商家品控明细  请求接口
      const request = this.isQcPunish
        ? qcList(this.params)
        : storeOrderAuditList(this.params)
      request.then(res => {
        const { list, totalCount } = res.data
        this.tableData = Object.freeze(list)
        this.total = totalCount
      })
    },
    codeBlur (val) {
      this.code = val
    },
    orderStatusChange (val) {
      this.orderStatus = val
    },
    priceTypeChange (val) {
      this.priceType = val
    },
    typeChange (val) {
      this.type = val
    },
    categoryIdChange (val) {
      this.categoryId = val ? val.resultId : ''
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    // 导出
    exportData () {
      qcListExport(this.params)
      this.$message({
        message: '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.code = ''
      this.type = ''
      this.orderStatus = ''
      this.priceType = ''
      this.categoryId = ''
      this.startTime = ''
      this.endTime = ''
      this.date = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>

<style></style>
